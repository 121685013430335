.App {
  height: 100vh;
  width: 100vw;

  .sections {
    width: 100%;
    height: calc(100vh - 70px);
    position: relative;
    top: 70px;
    scroll-behavior: smooth;
    scroll-snap-type: y proximity;
    // scrollbar-width: none; //for firefox
    // &::-webkit-scrollbar{
    //     display: none;
    // }

    > * {
      width: 100vw;
      height: calc(100vh - 70px);
      // scroll-snap-align: end;
    }
  }
}

@media screen and (min-width: 2000px) and (max-width: 2560px) {
  .App {
    .sections {
      height: calc(100vh - 80px);
      top: 80px;
      // scrollbar-width: none; //for firefox
      // &::-webkit-scrollbar{
      //     display: none;
      // }

      > * {
        height: calc(100vh - 80px);
      }
    }
  }
}
