.main-container {
  width: 100vw;
  height: max-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.main-logo-wrapper {
  height: 20%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-logo {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-text-container {
  height: 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-text {
  text-align: start;
  width: 90%;
  padding: 0;
  font-size: 16px;
}

.main-contact {
  height: 70%;
  width: 100%;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.main-contact-subtitle {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.2px;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.main-contact-title {
  font-weight: 350;
  letter-spacing: 0.2px;
  font-size: 28px;
  margin-bottom: 0;
  margin-top: 5px;
}

.contact-details-wrapper {
  background-color: hsla(0, 0%, 98%, 0.5);
  -webkit-box-shadow: 0px 0px 5px 0px rgb(221, 219, 219);
  -moz-box-shadow: 0px 0px 5px 0px rgba(221, 219, 219);
  box-shadow: 0px 0px 5px 0px rgba(221, 219, 219);
  margin-top: 30px;
  width: 90%;
  padding: 15px;
}

.contact-detail-title {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 0px;
}

.contact-text {
  line-height: 20px;
}

.contact-info-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.contact-info {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50px;
}

.contact-social-icon {
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 15px;
}

.social-icon-wrapper {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(221, 219, 219);
  -moz-box-shadow: 0px 0px 5px 0px rgba(221, 219, 219);
  box-shadow: 0px 0px 5px 0px rgba(221, 219, 219);
}

.social-icon {
  width: 15px;
  height: 15px;
}

/* contact form */

.contact-form-wrapper {
  justify-content: center;
  margin-top: 30px;
  width: 90%;
  padding: 15px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.contact-input {
  height: 45px;
  width: 100%;
  border-radius: 8px;
  padding-left: 12px;
  border: 1px solid rgb(229 231 235);
}

.contact-input:focus {
  padding-left: 12px;
}

#contact-message-area {
  border: 1px solid rgb(229 231 235);
  padding-left: 12px;
  padding-top: 8px;
  font-style: normal;
  border-radius: 8px;
}

.contact-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-button {
  width: 100%;
  height: 45px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 17px;
  color: white;
  background-color: rgb(17, 24, 39);
  border: none;
  outline: none;
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.contact-button:disabled {
  cursor: not-allowed;
}

.contact-button.submitted {
  background-color: #16a34a; /* Success green */
  width: 45px;
  height: 45px;
  border-radius: 50%;
  padding: 0;
  position: relative;
}

.button-text {
  transition: opacity 0.3s ease, transform 0.4s;
}

.contact-button.submitted .button-text {
  opacity: 0;
  transform: scale(0.8);
}

.checkmark {
  position: absolute;
  font-size: 20px;
  color: white;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.3s, transform 0.3s;
}

.contact-button.submitted .checkmark {
  opacity: 1;
  transform: scale(1);
}

.contact-section-wrapper {
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@media screen and (min-width: 768px) and (max-width: 1100px) {
  .main-text {
    width: 600px;
  }
  .contact-details-wrapper {
    width: 600px;
  }

  .contact-form-wrapper {
    width: 600px;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1440px) {
  .main-text {
    width: 600px;
    font-size: 18px;
  }

  .contact-section-wrapper {
    flex-direction: row;
    gap: 150px;
    /* align-items: center;
    margin-top: 50px; */
  }

  .contact-details-wrapper {
    width: 450px;
    margin-top: 0;
    /* align-self: flex-start;
    margin-top: 7px; */
  }

  .contact-form-wrapper {
    width: 450px;
    /* margin-top: 0;
    padding-top: 0; */
  }
}

@media screen and (min-width: 1440px) and (max-width: 2000px) {
  .main-text {
    width: 600px;
    font-size: 20px;
  }

  .contact-section-wrapper {
    flex-direction: row;
    gap: 150px;
  }

  .contact-details-wrapper {
    width: 500px;
    margin-top: 0;
  }

  .contact-form-wrapper {
    width: 500px;
  }

  .main-contact-title {
    font-size: 32px;
  }

  .main-contact-subtitle {
    font-size: 18px;
  }
}

@media screen and (min-width: 2000px) and (max-width: 2500px) {
  .main-text {
    width: 600px;
    font-size: 20px;
  }

  .contact-section-wrapper {
    flex-direction: row;
    gap: 150px;
  }

  .contact-details-wrapper {
    width: 500px;
    margin-top: 0;
  }

  .contact-form-wrapper {
    width: 500px;
  }

  .main-contact-title {
    font-size: 32px;
  }

  .main-contact-subtitle {
    font-size: 18px;
  }
}
